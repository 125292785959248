<template>
  <div class="edit-departments grid ggap-10">
    <!-- Selected departments -->
    <template v-if="propValue && propValue.length">
      <template v-if="currentTab && currentTab === 'agreementers'">
        <ItemDepartmentDrag
          v-for="(department, index) of propValue"
          :key="department.id"
          :length="index > 0 ? propValue[index - 1].users.length + propValue[index - 1].manual_users.length : 0"
          :item="department"
          isRead
        />
      </template>
      <template v-else>
        <ItemDepartment v-for="department of propValue" :key="department.id" :item="department" isRead />
      </template>
    </template>
    <BaseAlert v-else type="yellow"> Нет ни одного участника, видимо их никто не добавил!</BaseAlert>
  </div>
</template>

<script setup>
import { cloneDeep } from 'lodash'
// import { useStore } from 'vuex'
import { defineEmits, defineProps, toRefs, ref, computed } from 'vue'
import { BaseAlert } from '@/components'
import ItemDepartment from './ItemDepartment.vue'
import ItemDepartmentDrag from './ItemDepartmentDrag.vue'

// Emits
const emits = defineEmits(['update:departments'])

// Props
const props = defineProps({
  currentTab: {
    type: String,
    default: ''
  },
  departments: {
    type: Array,
    default: () => []
  }
})

// Data
const { currentTab, departments } = toRefs(props)
// const store = useStore()
// const isLoad = ref(false)
const selectedDepartments = ref([])

// Computed
const propValue = computed({
  get: () => departments.value,
  set: (val) => emits('update:departments', val)
})

// Created
// getData()
selectedDepartments.value = cloneDeep(propValue.value)

// Methods
// async function getData() {
//   isLoad.value = true
//   await store.dispatch('helpers/GET_DEPARTMENTS')
//   isLoad.value = false
// }
</script>

<style lang="scss" scoped>
.edit-departments {
  &__list {
    width: 700px;
    min-height: 50vh;
  }
}
</style>
